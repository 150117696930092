<template>
  <h1></h1>
  <div class="games">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div
          v-for="game in games"
          :key="game.id"
          class="col-12 col-md-4  block shake"
        >
          <GamesCard :game="game" />
        </div>
        <div v-if="games === null"><h2>Loading Games Please Wait</h2></div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import GamesCard from "../components/GamesCard.vue";
import EventService from "../services/EventService.js";

export default {
  name: "GamesPlayed",
  components: {
    GamesCard
  },
  data() {
    return {
      games: null
    };
  },
  created() {
    EventService.getGames()
      .then(response => {
        this.games = response.data;
      })
      .catch(error => {
        console.log(error);
      });
  }
};
</script>
<style lang="scss" scoped>
h1 {
  background: #0f60c9;
  color: #ffffff;
  margin-bottom: 3em;
}
.games {
  margin-bottom: 5%;
  .block {
    margin-top: 1em;
    z-index: 790;
    animation: shake 1s;
    &:hover{
      z-index: 900;
    }
  }
}
h2 {
  font-size: 2em;
  font-weight: bolder;
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
