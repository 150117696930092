<template>
  <router-link
    class="games-link"
    :to="{ name: 'GamesDetails', params: { id: game.id } }"
  >
    <div class="games-card">
      <!-- Display event data -->
      <img :src="game.imgSrc" />
      <p>{{ game.intro }}</p>
      <p>Read Full Report</p>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "GamesCard",
  props: {
    game: Object
  }
};
</script>

<style lang="scss" scoped>
.games-link {
  text-decoration: none;
  color: #000000;
}
.games-card {
  cursor: pointer;
  margin-bottom: 18px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
  transition: ease-in-out 0.8s;
  background: #ffffff;
  z-index: 800;
  img {
    max-width: 100%;
  }
  p {
    padding: 0 2em 1em ;
    &:first-of-type {
      padding: 1em 2em 0;
    }
  }
  &:hover {
    transform: scale(1.15);
    z-index: 810;
  }
  p {
    text-align: left;
    &:last-of-type {
      color: #752cf0;
    }
  }
}
</style>
